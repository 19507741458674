import * as THREE from 'three'
import Experience from '../Experience.js'

export default class HomeElements {
    constructor() {
        this.experience = new Experience()
        this.sizes = this.experience.sizes
        this.color = this.experience.color
        this.scene = this.experience.scene
        this.camera = this.experience.camera
        this.resources = this.experience.resources

        // Mouse movement
        this.mouse = this.experience.camera.mouse
        this.target1 = new THREE.Vector2()
        this.target2 = new THREE.Vector2()
        this.windowHalf = new THREE.Vector2(this.sizes.width / 2, this.sizes.height / 2)

        // Set home circles
        this.setGeometry()
        this.setMaterial()
        this.setMesh()

        // Set home circles movement
        this.setMoveElements()
    }

    setGeometry() {
        // small circle
        this.geometry = new THREE.RingGeometry(9.2, 10, 65) //TorusGeometry(10, 5, 16, 100)
        // Big circle
        this.geometry2 = new THREE.RingGeometry(10, 11.5, 70, 2)
    }

    setMaterial() {
        // Small circle
        this.material = new THREE.MeshStandardMaterial({
            color: this.color.two,
            wireframe: true,
            lightMapIntensity: 0
        })
        // Big circle
        this.material2 = new THREE.MeshStandardMaterial({
            color: this.color.three,
            wireframe: true,
            lightMapIntensity: 0
        })
    }

    setMesh() {
        // Set circle meshes
        this.mesh1 = new THREE.Mesh(this.geometry, this.material)
        this.mesh2 = new THREE.Mesh(this.geometry2, this.material2)
        this.mesh1.position.z = 5.9
        this.mesh2.position.z = 6
        this.mesh1.scale.set(1.4, 1.4, 1.4)
        this.mesh2.scale.set(1.4, 1.4, 1.4)

        this.scene.add(this.mesh1, this.mesh2)
    }

    setMoveElements() {
        // If not touch pointer
        if (!window.matchMedia("(pointer: coarse)").matches) {
            // Mouse move
            document.addEventListener('mousemove', this.onMouseMove.bind(this), false)
        }
        else {
            // Touch move
            document.addEventListener('touchmove', this.onTouchMove.bind(this), false)
        }
    }

    onMouseMove(event) {
        const newWindowHalf = new THREE.Vector2(window.innerWidth / 2, window.innerHeight / 2)

        this.mouse.y = (event.clientX - newWindowHalf.x)
        this.mouse.x = (event.clientY - newWindowHalf.y)

        this.windowHalf.copy(newWindowHalf)
    }

    onTouchMove(event) {
        const touches = event.touches

        if (touches.length > 0) {
            const touch = touches[0]
            const clientX = touch.clientX
            const clientY = touch.clientY

            const newWindowHalf = new THREE.Vector2(window.innerWidth / 2, window.innerHeight / 2)

            this.mouse.y = (clientX - newWindowHalf.x)
            this.mouse.x = (clientY - newWindowHalf.y)

            this.windowHalf.copy(newWindowHalf)
        }
    }

    resize() {
        this.setMoveElements()
    }

    update() {
        // If home
        if (this.camera.homeReady === true) {
            this.target1.x = - (1 - this.mouse.y) * 0.006
            this.target1.y = (1 - this.mouse.x) * 0.01

            this.target2.x = (1 - this.mouse.y) * 0.01
            this.target2.y = - (1 - this.mouse.x) * 0.008

            // Rotation speed and acceleration
            const distanceFromCenter = Math.sqrt(Math.pow(this.mouse.x, 2) + Math.pow(this.mouse.y, 2))

            const rotationSpeedFactor = 0.000006
            const minimumRotationSpeed = 0.001
            let rotationSpeed = distanceFromCenter * rotationSpeedFactor

            rotationSpeed = Math.max(rotationSpeed, minimumRotationSpeed)

            // Rotate circles
            this.mesh2.rotation.z += rotationSpeed
            this.mesh1.rotation.z -= rotationSpeed

            // Move circles
            this.mesh1.position.x += 0.05 * (this.target1.y - this.mesh1.position.x)
            this.mesh1.position.y += 0.05 * (this.target1.x - this.mesh1.position.y)

            this.mesh2.position.x += 0.05 * (this.target2.y - this.mesh2.position.x)
            this.mesh2.position.y += 0.05 * (this.target2.x - this.mesh2.position.y)
        }
    }
}