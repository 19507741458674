import * as THREE from 'three'
import Experience from '../Experience.js'

export default class Logo {
    constructor() {
        this.experience = new Experience()
        this.scene = this.experience.scene
        this.resources = this.experience.resources
        this.time = this.experience.time

        // Setup
        this.color = this.experience.color

        // Resource
        this.resource = this.resources.items.logoModel

        // Set Logo
        this.setModel()
    }

    setModel() {
        this.model = this.resource.scene
        this.model.traverse((child) => {
            if (child instanceof THREE.Mesh) {
                child.castShadow = true
            }
        })
        // this.model.scale.set(0.5, 0.5, 0.5)

        this.model.children[0].material.color = new THREE.Color(this.color.three)
        this.model.children[0].material.roughness = 1
        // this.model.children[0].material.metalness = 0.8
        this.model.children[0].material.lightMapIntensity = 0
        this.model.children[1].material.color = new THREE.Color(this.color.two)
        this.model.position.z = 0.8

        this.scene.add(this.model)
    }
}