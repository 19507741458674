import * as THREE from 'three'
import Experience from '../Experience.js'

export default class Contact {
    constructor() {
        this.experience = new Experience()
        this.scene = this.experience.scene
        this.sizes = this.experience.sizes
        this.camera = this.experience.camera

        // Setup
        this.color = this.experience.color

        // Mouse movement
        this.mouse = this.experience.camera.mouse
        this.target = new THREE.Vector2()
        this.windowHalf = new THREE.Vector2(this.sizes.width / 2, this.sizes.height / 2)

        // Set circle
        this.setCircle()

        // Set circle movement
        document.addEventListener('mousemove', this.onMouseMove.bind(this), false)
    }

    setCircle() {
        // Circle
        const geometry = new THREE.RingGeometry(15, 18.5, 30)
        this.material = new THREE.MeshStandardMaterial({
            color: this.color.one,
            wireframe: true,
            lightMapIntensity: 0
        })
        this.mesh = new THREE.Mesh(geometry, this.material)
        this.mesh.position.set(0, 0, 1)
        this.scene.add(this.mesh)
    }

    onMouseMove(event) {
        this.mouse.x = (event.clientX - this.windowHalf.x)
        this.mouse.y = (event.clientY - this.windowHalf.y)

    }

    update() {
        //If contact
        if (this.camera.contactReady === true) {
            // Rotate circle
            this.mesh.rotation.z += 0.001

            // If not touch pointer
            if (!window.matchMedia("(pointer: coarse)").matches) {
                // Move circle
                this.target.x = - (1 - this.mouse.x) * 0.02
                this.target.y = - (1 - this.mouse.y) * 0.05

                this.mesh.position.x += 0.02 * (this.target.y - this.mesh.position.x)
                this.mesh.position.y += 0.02 * (-148 + this.target.x - this.mesh.position.y)
            }
            else {
                // Position circle
                this.mesh.position.set(0, -150, -1)
            }
        }
    }
}