import * as THREE from 'three'
import { gsap } from 'gsap'
import Experience from './Experience.js'
// import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls.js'

export default class Camera {
    constructor() {
        this.experience = new Experience()
        this.sizes = this.experience.sizes
        this.scene = this.experience.scene
        this.canvas = this.experience.canvas

        // Setup
        this.mouse = new THREE.Vector2()

        this.animGsap = true

        this.homeReady = true
        this.aboutReady = false
        this.projectReady = false
        this.contactReady = false
        this.skillsReady = false

        // HTML elements
        this.projects = document.getElementById('projects')
        this.skills = document.getElementById('skills')
        this.contact = document.getElementById('contact')
        this.about = document.getElementById('about')
        this.homeProjects = document.getElementById('homeProjects')
        this.homeSkills = document.getElementById('homeSkills')
        this.homeContact = document.getElementById('homeContact')
        this.homeAbout = document.getElementById('homeAbout')

        this.btnMenu = document.getElementById('btnMenu')

        this.copyRight = document.getElementById('copyRight')

        // Add Camera
        this.setInstance()

        // Set movement for nav click
        this.setCameraMovement()

        //this.setControls()
    }

    setInstance() {
        this.instance = new THREE.PerspectiveCamera(75, this.sizes.width / this.sizes.height, 0.1, 100)
        this.scene.add(this.instance)
    }

    setCameraMovement() {
        // Nav click - Projects
        this.projects.addEventListener('click', () => {

            this.animGsap = false

            this.projectReady = true

            if (this.sizes.width < 1024) {
                this.projectCamera = 144
            } else {
                this.projectCamera = 148
            }

            gsap.to(this.instance.position, {
                duration: 1.8,
                x: 0,
                y: this.projectCamera,
                ease: 'power2.inOut',
                delay: 0.2,
                onComplete: () => {
                    this.experience.world.project.showProjects()
                    this.animGsap = true

                    this.homeReady = false
                }
            })
        })

        // Nav click - Skills
        this.skills.addEventListener('click', () => {
            this.animGsap = false

            this.skillsReady = true

            gsap.to(this.instance.position, {
                duration: 1.8,
                x: 140,
                y: 0,
                ease: 'power2.inOut',
                delay: 0.2,
                onComplete: () => {
                    this.experience.world.skills.showSkills()
                    this.animGsap = true

                    this.homeReady = false
                }
            })
        })

        // Nav click - Contact
        this.contact.addEventListener('click', () => {
            this.animGsap = false

            this.contactReady = true

            if (this.sizes.width < 1024) {
                this.contactCamera = -146
            } else {
                this.contactCamera = -150
            }
            gsap.to(this.instance.position, {
                duration: 1.8,
                x: 0,
                y: this.contactCamera,
                ease: 'power2.inOut',
                delay: 0.2,
                onComplete: () => {
                    this.animGsap = true

                    this.homeReady = false

                    this.copyRight.classList.add('active')
                }
            })
        })

        // Nav click -About
        this.about.addEventListener('click', () => {
            this.animGsap = false

            this.aboutReady = true

            gsap.to(this.instance.position, {
                duration: 1.8,
                x: -140,
                y: 0,
                ease: 'power2.inOut',
                delay: 0.2,
                onComplete: () => {
                    this.experience.world.about.showAbout()
                    this.animGsap = true

                    this.homeReady = false
                }
            })
        })

        // Btn-back-to-nav
        this.gsapBack = () => {
            this.animGsap = false

            this.homeReady = true

            this.copyRight.classList.remove('active')

            gsap.to(this.instance.position, {
                duration: 1.8,
                x: 0,
                y: 0,
                ease: 'power2.inOut',
                delay: 0.2,
                onComplete: () => {
                    this.animGsap = true

                    this.aboutReady = false
                    this.projectReady = false
                    this.contactReady = false
                    this.skillsReady = false

                    this.experience.world.project.hideProjects()
                    this.experience.world.skills.hideSkills()
                }
            })
        }

        // Btns back click
        this.homeProjects.addEventListener('click', this.gsapBack)
        this.homeSkills.addEventListener('click', this.gsapBack)
        this.homeContact.addEventListener('click', this.gsapBack)
        this.homeAbout.addEventListener('click', this.gsapBack)
    }

    // setControls()
    // {
    //     this.controls = new OrbitControls(this.instance, this.canvas)
    //     this.controls.enableDamping = true
    // }

    resize() {
        this.instance.aspect = this.sizes.width / this.sizes.height
        this.instance.updateProjectionMatrix()

        if (this.sizes.width > 1024) {
            this.instance.position.z = 22
            if (this.contactReady === true) { this.instance.position.y = -153 }
            else if (this.projectReady === true) { this.instance.position.y = 148 }
        } else {
            this.instance.position.z = 25

            if (this.contactReady === true) { this.instance.position.y = -145 }
            else if (this.projectReady === true) { this.instance.position.y = 143 }
        }
    }

    update() {
        //this.controls.update()
    }
}