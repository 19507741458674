import Lenis from '@studio-freight/lenis'
import Experience from './Experience/Experience.js'

// Set Lenis
const divTests = document.querySelectorAll('.detail-inner')
const lenisInstances = []

divTests.forEach(wrapper => {
  const lenisInstance = new Lenis({
    wrapper: wrapper,
    duration: 2,
    easing: (t) => (t === 1 ? 1 : 1 - Math.pow(2, -10 * t)), // Easing function
    direction: 'vertical',
    smooth: true,
    smoothTouch: false,
    touchMultiplier: 3
  })

  lenisInstances.push(lenisInstance)
})

function raf(time) {
  lenisInstances.forEach(instance => {
    instance.raf(time)
  })
  requestAnimationFrame(raf)
}

requestAnimationFrame(raf)

// Animation reveal
document.addEventListener("DOMContentLoaded", function () {
  var elementVisible
  var reveals = document.querySelectorAll(".reveal")

  divTests.forEach(wrapper => {
    wrapper.addEventListener("scroll", reveal)
  })

  function reveal() {

    if(window.matchMedia("(min-width: 600)").matches) {
      elementVisible = 100
    } else {
      elementVisible = 50
    }

    for (var i = 0; i < reveals.length; i++) {
      var windowHeight = window.innerHeight
      var elementTop = reveals[i].getBoundingClientRect().top

      if (elementTop < windowHeight - elementVisible) {
        reveals[i].classList.add("active")
      } else {
        reveals[i].classList.remove("active")
      }
    }
  }
})

// Experience
new Experience(document.querySelector('canvas.webgl'))