import Experience from '../Experience.js'

export default class Translation {
    constructor() {
        this.experience = new Experience()

        this.translations = {
            // ENGLISH
            en: {
                translation: "<span><p>EN</p></span>",
                copyright: "© Copyright 2024 by Hector Gauthier",
                homeProject: "Projects",
                homeSkills: "Skills",
                homeAbout: "About",
                message: "Welcome to our website.",
                aboutContent1: "<span>Hello! I'm Hector Gauthier, a creative developer.</span><br>After discovering the field of graphics, I naturally moved towards front-end development.<br>&#10140;",
                aboutContent2: "Always looking for improvement, I seek to enrich my skills while ensuring the quality and efficiency of my work.<br><span>My objective ? Design web interfaces that are both aesthetic and functional.</span>",
                aboutContent3: "<span>I am a Frenchman living in Quebec.</span><br>Apart from my passion for the web I am a cinema and video game addict as well as an amateur in skating and billiards.",
                titleSoftware: "Softwares",
                titleLanguage: "Languages",
                titleTool: "Tools",
                projectNubee: "Developed at <a class='button-link' target='_blank' href='https://nubee.ca/'>Nubee</a>",
                projectPerso: "Developed at home - Perso",
                titleValinouet: "The Valinouët",
                tasksValinouet: "| Front-end development | Customer Manager | Content integration",
                textValinouet: "Located in the Valin Mountains, approximately 50 km north of the city of Saguenay in Quebec, The Valinouët is a renowned winter sports resort.<br>Offering a multitude of winter activities such as downhill skiing, snowboarding , tubing, cross-country skiing and snowshoeing, this destination is popular for its abundant natural snow which earns it its slogan '100% natural snow'.",
                toolsValinouet: "HTML<br>SCSS<br>PHP<br>WordPress (Gutenberg)<br>ACF<br>WooCommerce plugin<br>WPML plugin",
                titleFbms: "Saguenay World Beer Festival",
                tasksFbms: "| Front-end development | Multi-theme development | Customer Manager | Content integration",
                textFbms: "The Saguenay World Beer Festival is one of the most important brewing events in Quebec. Attracting more than 90,000 visitors, it brings together more than sixty exhibitors and artisans, offers a selection of some 450 beers to taste, as well as a variety of local products.<br>In addition, the FBM offers shows featuring international artists on its main stage.<br>This website is linked to the FIRM project, the International Rhythms of the World Festival, because these two sites use the same WordPress manager but not the same theme (Broadcast Plugin).",
                toolsFbms: "HTML<br>SCSS<br>PHP<br>WordPress (Gutenberg)<br>ACF<br>WooCommerce plugin<br>Broadcast plugin (multisite)",
                titleFirm: "International Rhythms of the World Festival",
                tasksFirm: "| Front-end development | Multi-theme development | Customer Manager | Content integration",
                textFirm: "The International Rhythms of the World Festival is a unique multicultural event of its kind, it is dedicated to world music. He has established himself as a reference in Canada for artists in this field. Bringing together more than 950 musicians, dancers and artisans from the four corners of the world, it captivates the public with captivating percussion and dance performances.<br>This website is linked to the FBMS project, the World Beer Festival du Saguenay, because these two sites use the same WordPress manager but not the same theme (Plugin Broadcast).",
                toolsFirm: "HTML<br>SCSS<br>PHP<br>WordPress (Gutenberg)<br>ACF<br>WooCommerce plugin<br>Broadcast plugin (multisite)",
                titleHumanis: "Humanis - Continuing education Cégep of Chicoutimi",
                tasksHumanis: "| Web redesign | Front-end development | Customer Manager | Content integration",
                textHumanis: "Humanis represents the continuing education branch of Cégep of Chicoutimi, aiming to meet the needs of workers, businesses and people wishing to specialize or re-enter the job market. It offers advanced training courses, programs leading to obtaining an attestation of college studies (A.E.C), online training, recognition of prior learning and skills, as well as business services.",
                toolsHumanis: "HTML<br>SCSS<br>PHP<br>WordPress<br>ACF",
                titleDenturo: "Boulevard Denturology Clinic",
                tasksDenturo: "| Front-end development | Customer Manager | Content integration",
                textDenturo: "The Denturologie du Boulevard clinic was created in 1980 and established in 1985 in Chicoutimi. Sharing the same premises as the MJ Dental Laboratory, it has access to several cutting-edge technologies. It also allows us to offer a varied choice of all removable dental prostheses.",
                toolsDenturo: "HTML<br>SCSS<br>PHP<br>WordPress (Gutenberg)<br>ACF",
                titleLabyrinth: "Labyrin-three - a 3D experience",
                tasksLabyrinth: "| Front-end development | Back-end development | Design | 3D",
                textLabyrinth: "Labyrin-three is an experimental web project where I used the ThreeJs library to create a 3D maze mini-game with CannonJs physics. The challenge is to guide a ball through the maze using a single joystick.<br>The game's physics adapts to the capabilities of the device, requiring players to use the same device for fair competition.",
                toolsLabyrinth: "HTML<br>CSS<br>JavaScript<br>ThreeJs<br>CannonJs<br>NippleJs"
            },
            // FRENCH
            fr: {
                translation: "<span><p>FR</p></span>",
                copyright: "© Tous droits réservés 2024 par Hector Gauthier",
                homeProject: "Projets",
                homeSkills: "Aptitudes",
                homeAbout: "À propos",
                message: "Bienvenue sur notre site web.",
                aboutContent1: "<span>Bonjour ! Je suis Hector Gauthier, un développeur créatif.</span><br>Après avoir découvert le domaine du graphisme, je me suis naturellement orienté vers le développement front-end.<br>&#10140;",
                aboutContent2: "Toujours en quête d'amélioration, je cherche à enrichir mes compétences tout en assurant la qualité et l'efficacité de mon travail.<br><span>Mon objectif ? Concevoir des interfaces web à la fois esthétiques et fonctionnelles.</span>",
                aboutContent3: "<span>Je suis un français résidant à Québec.</span><br>En dehors de ma passion pour le web je suis un accro de cinéma et de jeux vidéo ainsi qu'un amateur en patin et billard.",
                titleSoftware: "Logiciels",
                titleLanguage: "Langages",
                titleTool: "Outils",
                projectNubee: "Développé chez <a class='button-link' target='_blank' href='https://nubee.ca/'>Nubee</a>",
                projectPerso: "Développé chez moi - Perso",
                titleValinouet: "Le Valinouët",
                tasksValinouet: "| Développement front-end | Gestionnaire client | Intégration de contenu",
                textValinouet: "Située dans les monts Valin, à environ 50 km au nord de la ville de Saguenay au Québec, Le Valinouët est une station de sports d'hiver réputée.<br>Offrant une multitude d'activités hivernales telles que le ski alpin, le snowboard, la glissade en tube, le ski de fond et la raquette à neige, cette destination est prisée pour son enneigement naturel abondant qui lui vaut son slogan 'Neige 100% naturelle'.",
                toolsValinouet: "HTML<br>SCSS<br>PHP<br>WordPress (Gutenberg)<br>Plugin WooCommerce<br>Plugin WPML",
                titleFbms: "Festival des Bières du Monde du Saguenay",
                tasksFbms: "| Développement front-end | Développement multi-thème | Gestionnaire client | Intégration de contenu",
                textFbms: "Le Festival des bières du Monde de Saguenay figure parmi les événements brassicoles les plus importants au Québec. Attirant plus de 90 000 visiteurs, il réunit plus d'une soixantaine d'exposants et d'artisans, propose une sélection de quelque 450 bières à déguster, ainsi qu'une variété de produits du terroir.<br>De plus, le FBM offre des spectacles mettant en vedette des artistes internationaux sur sa scène principale.<br>Ce site web est en lien avec le projet FIRM, le Festival International des Rythmes du Monde, car ces deux sites utilises le même gestionnaire WordPress mais pas le même thème (Plugin Broadcast).",
                toolsFbms: "HTML<br>SCSS<br>PHP<br>WordPress (Gutenberg)<br>Plugin WooCommerce<br>Plugin Broadcast (multisite)",
                titleFirm: "Festival International des Rythmes du Monde",
                tasksFirm: "| Développement front-end | Développement multi-thème | Gestionnaire client | Intégration de contenu",
                textFirm: "Le Festival International des Rythmes du Monde est un événement multiculturel unique en son genre, il est dédié à la musique du monde. Il s'est imposé comme une référence au Canada pour les artistes de ce domaine. Réunissant plus de 950 musiciens, danseurs et artisans venant des quatre coins du monde, il captive le public avec des performances de percussions et de danse envoûtantes.<br>Ce site web est en lien avec le projet FBMS, le Festival des Bières du Monde du Saguenay, car ces deux sites utilises le même gestionnaire WordPress mais pas le même thème (Plugin Broadcast).",
                toolsFirm: "HTML<br>SCSS<br>PHP<br>WordPress (Gutenberg)<br>Plugin WooCommerce<br>Plugin Broadcast (multisite)",
                titleHumanis: "Humanis - Formation continue Cégep de Chicoutimi",
                tasksHumanis: "| Refont web | Développement front-end | Gestionnaire client | Intégration de contenu",
                textHumanis: "Humanis représente la branche de formation continue du Cégep de Chicoutimi, visant à répondre aux besoins des travailleurs, des entreprises et des personnes soucieuses de se spécialiser ou de réintégrer le marché du travail. Elle offre des cours de perfectionnement, des programmes menant à l’obtention d’une attestation d’études collégiales (A.E.C), des formations en ligne, la reconnaissance des acquis et des compétences, ainsi que des services aux entreprises.",
                toolsHumanis: "HTML<br>SCSS<br>PHP<br>WordPress (Gutenberg)<br>Plugin WooCommerce<br>Plugin Broadcast (multisite)",
                titleDenturo: "Clinique de Denturologie du Boulevard",
                tasksDenturo: "| Développement front-end | Gestionnaire client | Intégration de contenu",
                textDenturo: "La clinique Denturologie du Boulevard a été créée en 1980 et établie en 1985 à Chicoutimi. Partageant les mêmes locaux que le Laboratoire Dentaire MJ, elle a un accès à plusieurs technologies à la fine pointe. Elle permet également de pouvoir offrir un choix varié de toutes prothèses dentaires amovibles.",
                toolsDenturo: "HTML<br>SCSS<br>PHP<br>WordPress (Gutenberg)<br>Plugin WooCommerce<br>Plugin Broadcast (multisite)",
                titleLabyrinth: "Labyrin-three - une expérience 3D",
                tasksLabyrinth: "| Développement front-end | Développement back-end | Design | 3D",
                textLabyrinth: "Labyrin-three est un projet web expérimental où j'ai utilisé la librairie ThreeJs pour créer un mini-jeu de labyrinthe en 3D avec la physique de CannonJs. Le défi est de guider une bille à travers le labyrinthe en utilisant un seul joystick.<br>La physique du jeu s'adapte aux capacités de l'appareil, ce qui nécessite que les joueurs utilisent le même dispositif pour une compétition équitable.",
                toolsLabyrinth: "HTML<br>CSS<br>JavaScript<br>ThreeJs<br>CannonJs<br>NippleJs<br>Blender"
            }
        }

        // Default language
        this.currentLanguage = 'en'
    }
}