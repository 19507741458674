import * as THREE from 'three'
import Experience from './Experience.js'

export default class Raycaster {
    constructor() {
        this.experience = new Experience()
        this.sizes = this.experience.sizes
        this.scene = this.experience.scene
        this.time = this.experience.time
        this.canvas = this.experience.canvas
        this.camera = this.experience.camera

        // Setup
        this.pointPositionTextAbout = this.pointPositionTextAbout ?? null

        // Mouse movement
        this.mouse = this.experience.camera.mouse
        this.targetTextAbout = new THREE.Vector2()
        this.targetLogicielSkills = new THREE.Vector2()
        this.targetLanguageSkills = new THREE.Vector2()
        this.windowHalf = new THREE.Vector2(this.sizes.width / 2, this.sizes.height / 2)

        // HTML elements
        this.text = document.querySelector('.about-first-paragraph')

        // Set raycaster
        this.setRaycaster()

        // Set HTML webGL elements
        this.setPoints()

        // Move HTML elements
        document.addEventListener('mousemove', this.onMouseMove.bind(this), false)
    }

    setRaycaster() {
        // Raycaster
        this.instance = new THREE.Raycaster()
    }

    setPoints() {
        if (this.sizes.width > 1024) {
            // DESKTOP
            this.points = [
                // Buttons navigation
                {
                    position: new THREE.Vector3(0, 8, 1),
                    element: document.querySelector('.projects')
                },
                {
                    position: new THREE.Vector3(8, 0, 1),
                    element: document.querySelector('.skills')
                },
                {
                    position: new THREE.Vector3(0, -8, 1),
                    element: document.querySelector('.contact')
                },
                {
                    position: new THREE.Vector3(-8, 0, 1),
                    element: document.querySelector('.about')
                },

                //Buttons back
                {
                    position: new THREE.Vector3(0, 138, 0.001),
                    element: document.querySelector('.home-projects')
                },
                {
                    position: new THREE.Vector3(122, 0, 0.001),
                    element: document.querySelector('.home-skills')
                },
                {
                    position: new THREE.Vector3(0, -143, 0.001),
                    element: document.querySelector('.home-contact')
                },
                {
                    position: new THREE.Vector3(-122, 0, 0.001),
                    element: document.querySelector('.home-about')
                },

                //Text about
                {
                    position: new THREE.Vector3(-145, 0, 0.002),
                    element: document.querySelector('.about-paragraph'),
                    name: 'text-about'
                },

                //Projects
                {
                    position: new THREE.Vector3(-10, 154, 0.002),
                    element: document.querySelector('.project-vali'),
                    name: 'project-vali'
                },
                {
                    position: new THREE.Vector3(0, 154, 0.002),
                    element: document.querySelector('.project-fbms'),
                    name: 'project-fbms'
                },
                {
                    position: new THREE.Vector3(10, 154, 0.002),
                    element: document.querySelector('.project-firm'),
                    name: 'project-firm'
                },
                {
                    position: new THREE.Vector3(-10, 145, 0.002),
                    element: document.querySelector('.project-humanis'),
                    name: 'project-humanis'
                },
                {
                    position: new THREE.Vector3(0, 145, 0.002),
                    element: document.querySelector('.project-db'),
                    name: 'project-db'
                },
                {
                    position: new THREE.Vector3(10, 145, 0.002),
                    element: document.querySelector('.project-labyrinth'),
                    name: 'project-labyrinth'
                },

                //Skills
                {
                    position: new THREE.Vector3(145, 6.5, 0.002),
                    element: document.querySelector('.logiciel'),
                    name: 'text-logiciel'
                },
                {
                    position: new THREE.Vector3(145, -6.5, 0.002),
                    element: document.querySelector('.language'),
                    name: 'text-language'
                },

                //Medias contact
                {
                    position: new THREE.Vector3(-10, -150, 0.001),
                    element: document.querySelector('.discord')
                },
                {
                    position: new THREE.Vector3(0, -153, 0.001),
                    element: document.querySelector('.email')
                },
                {
                    position: new THREE.Vector3(10, -150, 0.001),
                    element: document.querySelector('.linkedin')
                },
                // {
                //     position: new THREE.Vector3(-15, -150, 0.001),
                //     element: document.querySelector('.discord')
                // },
                // {
                //     position: new THREE.Vector3(-5, -153, 0.001),
                //     element: document.querySelector('.git')
                // },
                // {
                //     position: new THREE.Vector3(5, -153, 0.001),
                //     element: document.querySelector('.linkedin')
                // },
                // {
                //     position: new THREE.Vector3(15, -150, 0.001),
                //     element: document.querySelector('.email')
                // },
            ]
        } else if (this.sizes.width < 1024) {
            // TABLET & MOBILE
            if (window.matchMedia("(orientation: landscape)").matches) {
                this.points = [
                    // Buttons navigation
                    {
                        position: new THREE.Vector3(0, 8, 1),
                        element: document.querySelector('.projects')
                    },
                    {
                        position: new THREE.Vector3(10, 0, 1),
                        element: document.querySelector('.skills')
                    },
                    {
                        position: new THREE.Vector3(0, -8, 1),
                        element: document.querySelector('.contact')
                    },
                    {
                        position: new THREE.Vector3(-10, 0, 1),
                        element: document.querySelector('.about')
                    },

                    //Buttons back
                    {
                        position: new THREE.Vector3(0, 132, 0.001),
                        element: document.querySelector('.home-projects')
                    },
                    {
                        position: new THREE.Vector3(140, -16, 0.001),
                        element: document.querySelector('.home-skills')
                    },
                    {
                        position: new THREE.Vector3(0, -132, 0.001),
                        element: document.querySelector('.home-contact')
                    },
                    {
                        position: new THREE.Vector3(-140, -16, 0.001),
                        element: document.querySelector('.home-about')
                    },

                    //Text about
                    {
                        position: new THREE.Vector3(-140, 4, 0.002),
                        element: document.querySelector('.about-paragraph'),
                        name: 'text-about'
                    },

                    //Projects
                    {
                        position: new THREE.Vector3(-10, 155, 0.002),
                        element: document.querySelector('.project-vali'),
                        name: 'project-vali'
                    },
                    {
                        position: new THREE.Vector3(0, 155, 0.002),
                        element: document.querySelector('.project-fbms'),
                        name: 'project-fbms'
                    },
                    {
                        position: new THREE.Vector3(10, 155, 0.002),
                        element: document.querySelector('.project-firm'),
                        name: 'project-firm'
                    },
                    {
                        position: new THREE.Vector3(-10, 145, 0.002),
                        element: document.querySelector('.project-humanis'),
                        name: 'project-humanis'
                    },
                    {
                        position: new THREE.Vector3(0, 145, 0.002),
                        element: document.querySelector('.project-db'),
                        name: 'project-db'
                    },
                    {
                        position: new THREE.Vector3(10, 145, 0.002),
                        element: document.querySelector('.project-labyrinth'),
                        name: 'project-labyrinth'
                    },

                    //Skills
                    {
                        position: new THREE.Vector3(135, 8, 0.002),
                        element: document.querySelector('.logiciel'),
                        name: 'text-logiciel'
                    },
                    {
                        position: new THREE.Vector3(135, -6, 0.002),
                        element: document.querySelector('.language'),
                        name: 'text-language'
                    },

                    //Medias contact
                    {
                        position: new THREE.Vector3(-8, -140, 0.001),
                        element: document.querySelector('.discord')
                    },
                    {
                        position: new THREE.Vector3(0, -153, 0.001),
                        element: document.querySelector('.email')
                    },
                    {
                        position: new THREE.Vector3(8, -140, 0.001),
                        element: document.querySelector('.linkedin')
                    },
                    // {
                    //     position: new THREE.Vector3(-8, -140, 0.001),
                    //     element: document.querySelector('.discord')
                    // },
                    // {
                    //     position: new THREE.Vector3(-8, -153, 0.001),
                    //     element: document.querySelector('.git')
                    // },
                    // {
                    //     position: new THREE.Vector3(8, -153, 0.001),
                    //     element: document.querySelector('.linkedin')
                    // },
                    // {
                    //     position: new THREE.Vector3(8, -140, 0.001),
                    //     element: document.querySelector('.email')
                    // },
                ]
            } else {
                // MOBILE
                this.points = [
                    // Buttons navigation
                    {
                        position: new THREE.Vector3(0, 8, 1),
                        element: document.querySelector('.projects')
                    },
                    {
                        position: new THREE.Vector3(6, 0, 1),
                        element: document.querySelector('.skills')
                    },
                    {
                        position: new THREE.Vector3(0, -8, 1),
                        element: document.querySelector('.contact')
                    },
                    {
                        position: new THREE.Vector3(-6, 0, 1),
                        element: document.querySelector('.about')
                    },

                    //Buttons back
                    {
                        position: new THREE.Vector3(0, 130, 0.001),
                        element: document.querySelector('.home-projects')
                    },
                    {
                        position: new THREE.Vector3(140, -16, 0.001),
                        element: document.querySelector('.home-skills')
                    },
                    {
                        position: new THREE.Vector3(0, -132, 0.001),
                        element: document.querySelector('.home-contact')
                    },
                    {
                        position: new THREE.Vector3(-140, -16, 0.001),
                        element: document.querySelector('.home-about')
                    },

                    //Text about
                    {
                        position: new THREE.Vector3(-140, 4, 0.002),
                        element: document.querySelector('.about-paragraph'),
                        name: 'text-about'
                    },

                    //Projects
                    {
                        position: new THREE.Vector3(-4, 155, 0.002),
                        element: document.querySelector('.project-vali'),
                    },
                    {
                        position: new THREE.Vector3(4, 155, 0.002),
                        element: document.querySelector('.project-fbms'),
                    },
                    {
                        position: new THREE.Vector3(-4, 147, 0.002),
                        element: document.querySelector('.project-firm'),
                    },
                    {
                        position: new THREE.Vector3(4, 147, 0.002),
                        element: document.querySelector('.project-humanis'),
                    },
                    {
                        position: new THREE.Vector3(-4, 139, 0.002),
                        element: document.querySelector('.project-db'),
                    },
                    {
                        position: new THREE.Vector3(4, 139, 0.002),
                        element: document.querySelector('.project-labyrinth'),
                    },

                    //Skills
                    {
                        position: new THREE.Vector3(135, 8, 0.002),
                        element: document.querySelector('.logiciel'),
                        name: 'text-logiciel'
                    },
                    {
                        position: new THREE.Vector3(135, -6, 0.002),
                        element: document.querySelector('.language'),
                        name: 'text-language'
                    },

                    //Medias contact
                    {
                        position: new THREE.Vector3(-4, -144, 0.001),
                        element: document.querySelector('.discord')
                    },
                    {
                        position: new THREE.Vector3(0, -151, 0.001),
                        element: document.querySelector('.email')
                    },
                    {
                        position: new THREE.Vector3(4, -144, 0.001),
                        element: document.querySelector('.linkedin')
                    },
                    // {
                    //     position: new THREE.Vector3(-4, -144, 0.001),
                    //     element: document.querySelector('.discord')
                    // },
                    // {
                    //     position: new THREE.Vector3(-4, -152, 0.001),
                    //     element: document.querySelector('.git')
                    // },
                    // {
                    //     position: new THREE.Vector3(4, -152, 0.001),
                    //     element: document.querySelector('.linkedin')
                    // },
                    // {
                    //     position: new THREE.Vector3(4, -144, 0.001),
                    //     element: document.querySelector('.email')
                    // },
                ]
            }
        }
    }

    onMouseMove(event) {
        this.mouse.x = (event.clientX - this.windowHalf.x)
        this.mouse.y = (event.clientY - this.windowHalf.y)
    }

    resize() {
        this.setPoints()
    }

    update() {
        this.targetTextAbout.y = (1 - this.mouse.y) * 0.0015

        this.targetLogicielSkills.y = (1 - this.mouse.y) * 0.003
        this.targetLanguageSkills.y = - (1 - this.mouse.y) * 0.006

        // If Scene ready
        if (this.experience.sceneReady) {
            // Got through each point
            for (const point of this.points) {
                const screenPosition = point.position.clone()
                screenPosition.project(this.camera.instance)

                this.instance.setFromCamera(screenPosition, this.camera.instance)
                const intersects = this.instance.intersectObjects(this.scene.children, true)

                if (intersects.length === 0) {
                    point.element.classList.add('visible')
                }
                else {
                    const intersectionDistance = intersects[0].distance
                    const pointDistance = point.position.distanceTo(this.camera.instance.position)

                    point.element.classList.add('visible')
                }

                const translateX = screenPosition.x * this.sizes.width * 0.5
                const translateY = - screenPosition.y * this.sizes.height * 0.5
                point.element.style.transform = `translate( ${translateX}px, ${translateY}px )`

                // If not touch pointer
                if (!window.matchMedia("(pointer: coarse)").matches) {
                    // Move HTML webGL elements

                    // Text about
                    if (point.name === 'text-about' && this.camera.aboutReady === true) {
                        this.pointPositionTextAbout = point.position
                        point.position.x += 0.05 * (-140 + this.targetTextAbout.y - this.pointPositionTextAbout.x)
                    }
                    // Texts skills
                    if (this.sizes.width > 1024) {
                        if (point.name === 'text-logiciel' && this.camera.skillsReady === true) {
                            this.pointPositionLogicielSkills = point.position
                            point.position.x += 0.05 * (145 + this.targetLogicielSkills.y - this.pointPositionLogicielSkills.x)
                        }
                        if (point.name === 'text-language' && this.camera.skillsReady === true) {
                            this.pointPositionLanguageSkills = point.position
                            point.position.x += 0.05 * (145 + this.targetLanguageSkills.y - this.pointPositionLanguageSkills.x)
                        }
                    } else {
                        if (point.name === 'text-logiciel' && this.camera.skillsReady === true) {
                            this.pointPositionLogicielSkills = point.position
                            point.position.x += 0.05 * (141 + this.targetLogicielSkills.y - this.pointPositionLogicielSkills.x)
                        }
                        if (point.name === 'text-language' && this.camera.skillsReady === true) {
                            this.pointPositionLanguageSkills = point.position
                            point.position.x += 0.05 * (141 + this.targetLanguageSkills.y - this.pointPositionLanguageSkills.x)
                        }
                    }
                }
                else {
                    // Position HTML webGL elements

                    // Text about
                    if (point.name === 'text-about') {
                        point.position.x = -140
                    }

                    // Texts skills
                    if (this.sizes.width < 1024) {
                        // If not touch pointer
                        if (window.matchMedia("(orientation: landscape)").matches) {
                            if (point.name === 'text-logiciel') {
                                point.position.y = 2
                                point.position.x = 126
                            }
                            if (point.name === 'text-language') {
                                point.position.y = 2
                                point.position.x = 154
                            }
                        }
                        else {
                            if (point.name === 'text-logiciel') {
                                point.position.y = 8
                                point.position.x = 140
                            }
                            if (point.name === 'text-language') {
                                point.position.y = -6
                                point.position.x = 140
                            }
                        }
                    }
                    else if (this.sizes.width < 600) {
                        if (point.name === 'text-logiciel') {
                            point.position.y = 8
                            point.position.x = 140
                        }
                        if (point.name === 'text-language') {
                            point.position.y = -6
                            point.position.x = 140
                        }
                    }
                }
            }
        }

    }
}