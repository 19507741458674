import * as THREE from 'three'
import Experience from '../Experience.js'

export default class Environment {
    constructor() {
        this.experience = new Experience()
        this.sizes = this.experience.sizes
        this.scene = this.experience.scene
        this.resources = this.experience.resources
        this.camera = this.experience.camera

        // Setup
        this.color = this.experience.color

        // Mouse movement
        this.mouse = this.experience.camera.mouse
        this.target = new THREE.Vector2()
        this.windowHalf = new THREE.Vector2(this.sizes.width / 2, this.sizes.height / 2)

        // Set lights
        this.setPointLights()
        this.setAmbientLight()

        // Set pointLight movement
        this.setMoveLight()
    }

    setPointLights() {
        // Point Light
        this.pointLight = new THREE.PointLight(this.color.three, 8, 14, 0)
        this.pointLight.castShadow = true
        this.pointLight.shadow.camera.far = 40
        this.pointLight.shadow.mapSize.set(1024, 1024)
        this.pointLight.position.set(0, 0, 10)
        this.scene.add(this.pointLight)
    }

    setAmbientLight() {
        // AmbientLight
        this.ambientLight = new THREE.HemisphereLight(0xffffff, 0x0A0A0A, 3)
        this.ambientLight.position.set(0, 0, 10)
        this.scene.add(this.ambientLight)
    }

    setMoveLight() {
        // If not touch pointer
        if (!window.matchMedia("(pointer: coarse)").matches) {
            // Mouse move
            document.addEventListener('mousemove', this.onMouseMove.bind(this), false)
        }
        else {
            // Touch move
            document.addEventListener('touchmove', this.onTouchMove.bind(this), false)
        }
    }

    onMouseMove(event) {
        const newWindowHalf = new THREE.Vector2(window.innerWidth / 2, window.innerHeight / 2)

        this.mouse.y = (event.clientX - newWindowHalf.x)
        this.mouse.x = (event.clientY - newWindowHalf.y)

        this.windowHalf.copy(newWindowHalf)
    }

    onTouchMove(event) {

        const touches = event.touches

        if (touches.length > 0) {
            const touch = touches[0]
            const clientX = touch.clientX
            const clientY = touch.clientY

            const newWindowHalf = new THREE.Vector2(window.innerWidth / 2, window.innerHeight / 2)

            this.mouse.y = (clientX - newWindowHalf.x)
            this.mouse.x = (clientY - newWindowHalf.y)

            this.windowHalf.copy(newWindowHalf)
        }
    }

    resize() {
        this.setMoveLight()
    }

    update() {
        // If home
        if (this.camera.homeReady === true) {
            this.target.x = (1 - this.mouse.x) * 0.015
            this.target.y = - (1 - this.mouse.y) * 0.02

            // Move pointLight
            this.pointLight.position.x += 0.05 * (this.target.y - this.pointLight.position.x)
            this.pointLight.position.y += 0.05 * (this.target.x - this.pointLight.position.y)
        }
    }
}