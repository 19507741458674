import * as THREE from 'three'
import { gsap } from 'gsap'

import Translation from './Translation/translation.js'
import App from './Translation/app.js'

import Sizes from './Utils/Sizes.js'
import Time from './Utils/Time.js'
import Camera from './Camera.js'
import Renderer from './Renderer.js'
import Raycaster from './Raycaster.js'
import World from './World/World.js'
import Resources from './Utils/Resources.js'
import MouseElement from './MouseElement.js'

import sources from './sources.js'

let instance = null

export default class Experience {
    constructor(_canvas) {
        // Scene loading
        this.sceneReady = false
        this.setLoadingManager()

        // Singleton
        if (instance) {
            return instance
        }
        instance = this

        // Global access
        window.experience = this

        // Options
        this.canvas = _canvas

        // Colors
        this.colorBlack = new THREE.Color(0x0A0A0A).convertSRGBToLinear()
        this.colorWhite = new THREE.Color(0xFCFBE9).convertSRGBToLinear()
        this.colorOrange = new THREE.Color(0xEB661B).convertSRGBToLinear()

        // Current colors
        this.color = {
            one: this.colorBlack,
            two: this.colorWhite,
            three: this.colorOrange
        }

        // Translation
        this.translation = new Translation()
        this.app = new App()

        // Setup
        this.sizes = new Sizes()
        this.time = new Time()
        this.scene = new THREE.Scene()
        this.resources = new Resources(sources)
        this.camera = new Camera()
        this.renderer = new Renderer()
        this.world = new World()
        this.raycaster = new Raycaster()
        this.mouse = new MouseElement()

        // HTML Elements
        this.body = document.getElementById('body')
        this.colorButton = document.getElementById('colorButton')
        this.colorTrad = document.getElementById('colorTrans')

        // List change colors
        this.colors = [
            { one: this.colorWhite, two: this.colorBlack, three: this.colorOrange, name: 'white' },
            { one: this.colorOrange, two: this.colorBlack, three: this.colorWhite, name: 'orange' },
            { one: this.colorBlack, two: this.colorWhite, three: this.colorOrange, name: 'black' }
        ]
        this.currentColorIndex = 0

        // Change color
        this.colorButton.addEventListener('click', () => {
            this.color = this.colors[this.currentColorIndex]

            this.changeColor(this.colors[this.currentColorIndex].name)

            this.currentColorIndex = (this.currentColorIndex + 1) % this.colors.length
        })

        // Resize event
        this.sizes.on('resize', () => {
            this.resize()
        })

        // Time tick event
        this.time.on('tick', () => {
            this.update()
        })
    }

    changeColor(currentColor) {
        // Remove current color html
        this.body.classList.remove('black', 'white', 'orange')

        // Background
        this.world.background.materialBackground.color = new THREE.Color(this.color.two)
        this.world.background.material.color = new THREE.Color(this.color.one)
        // Home
        this.world.homeElements.material.color = new THREE.Color(this.color.three)
        this.world.homeElements.material2.color = new THREE.Color(this.color.two)
        // Logo
        this.world.logo.model.children[0].material.color = new THREE.Color(this.color.three)
        this.world.logo.model.children[1].material.color = new THREE.Color(this.color.two)

        // About
        this.world.about.material.color = new THREE.Color(this.color.one)

        // Project
        this.world.project.material.color = new THREE.Color(this.color.one)

        // Skill
        this.world.skills.material.color = new THREE.Color(this.color.one)

        // Contact
        this.world.contact.material.color = new THREE.Color(this.color.one)

        // PointLight
        this.world.environment.pointLight.color = new THREE.Color(this.color.three)

        // Add current color html
        this.body.classList.add(currentColor)

    }

    setLoadingManager() {
        //HTML elements
        this.loadingInner = document.getElementById('onload')
        this.loadingTextInner = document.querySelector('.loading-text')
        this.loadingBarInner = document.querySelector('.loading-inner')
        this.loadingFirstText = document.querySelector('.first-element')
        this.loadingSecondText = document.querySelector('.second-element')
        this.loadingThirdText = document.querySelector('.third-element')
        this.loadingBarElement = document.querySelector('.loading-bar')
        this.loadingManager = new THREE.LoadingManager(
            // Loaded
            () => {
                window.setTimeout(() => {

                    // Animate Textes
                    gsap.to(this.loadingFirstText, {
                        duration: 1.8,
                        left: '0%',
                        ease: 'power4.out(1.5)'
                    })
                    gsap.to(this.loadingSecondText, {
                        duration: 1.6,
                        left: '0%',
                        ease: 'power4.out(1.7)',
                        delay: 1.6
                    })
                    gsap.to(this.loadingThirdText, {
                        duration: 2.2,
                        left: '0%',
                        ease: 'power4.out(1.7)',
                        delay: 1.8
                    })

                    this.world.about.mesh.position.set(-140, 8, -1)
                    this.world.project.mesh.position.set(0, 148, -1)
                    this.world.skills.mesh.position.set(140, -8, -1)
                    this.world.contact.mesh.position.set(0, -150, -1)
                }, 1000)

                window.setTimeout(() => {
                    this.loadingTextInner.classList.remove('visible')
                    this.loadingBarInner.classList.remove('visible')
                }, 5500)

                // Animate overlay
                gsap.to(this.loadingInner, {
                    duration: 1,
                    opacity: 0,
                    delay: 6.8,
                    onComplete: () => {
                        setTimeout(() => {
                            this.loadingInner.style.display = 'none'
                        }, 500)
                    }
                })

                // Animate camera position after loading
                if (this.sizes.width > 1024) {
                    gsap.to(this.camera.instance.position, {
                        duration: 2,
                        x: 0,
                        y: 0,
                        z: 22,
                        ease: 'power2.inOut',
                        delay: 6
                    })
                } else {
                    gsap.to(this.camera.instance.position, {
                        duration: 2,
                        x: 0,
                        y: 0,
                        z: 25,
                        ease: 'power2.inOut',
                        delay: 6
                    })
                }

                // Animate html after loading and camera
                window.setTimeout(() => {
                    this.sceneReady = true

                    this.colorTrad.classList.add('visible')
                }, 8000)
            },

            // Progress
            (itemUrl, itemsLoaded, itemsTotal) => {
                // Calculate the progress and update the loadingBarElement
                this.progressRatio = itemsLoaded / itemsTotal

                const scaleYValue = 1 - this.progressRatio
                this.loadingBarElement.style.transform = `scaleY(${scaleYValue})`
            }
        )
    }

    resize() {
        this.camera.resize()
        this.renderer.resize()
        this.raycaster.resize()

        this.world.resize()
        this.mouse.resizeButton()
    }

    update() {
        this.camera.update()
        this.raycaster.update()
        this.world.update()
        this.renderer.update()
    }

    destroy() {
        this.sizes.off('resize')
        this.time.off('tick')

        // Traverse the whole scene
        this.scene.traverse((child) => {
            // Test if it's a mesh
            if (child instanceof THREE.Mesh) {
                child.geometry.dispose()

                // Loop through the material properties
                for (const key in child.material) {
                    const value = child.material[key]

                    // Test if there is a dispose function
                    if (value && typeof value.dispose === 'function') {
                        value.dispose()
                    }
                }
            }
        })

        this.camera.controls.dispose()
        this.renderer.instance.dispose()
    }
}