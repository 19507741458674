import * as THREE from 'three'
import { gsap } from 'gsap'
import Experience from '../Experience.js'

export default class About {
    constructor() {
        this.experience = new Experience()
        this.scene = this.experience.scene
        this.sizes = this.experience.sizes
        this.camera = this.experience.camera

        // Setup
        this.color = this.experience.color

        // Mouse movement
        this.mouse = this.experience.camera.mouse
        this.target = new THREE.Vector2()
        this.windowHalf = new THREE.Vector2(this.sizes.width / 2, this.sizes.height / 2)

        // Set texts
        this.actions = ['first-about', 'second-about', 'third-about']
        this.index = 1

        this.zoneTextAbout = document.getElementById('textAbout')
        this.numberSlide = document.getElementById('numberSlide')
        this.numberSlideFirstText = document.querySelector('#numberSlide p')

        // Set circle
        this.setCircle()

        // Switch text about
        this.numberSlide.addEventListener('click', () => this.changeTextAbout())

        // Set circle movement
        document.addEventListener('mousemove', this.onMouseMove.bind(this), false)
    }

    showAbout() {
        // Show first text
        gsap.to(this.numberSlideFirstText, {
            duration: 0.2,
            scale: 1,
            ease: 'power1.in'
        })
    }

    changeTextAbout() {
        this.numberSlide.style.transform = 'scale(0,0)'
        this.numberSlide.style.transition = 'transform 0.3s ease-in-out, font-size 0.4s ease'
        setTimeout(() => {

            this.zoneTextAbout.classList.remove('first-about', 'second-about', 'third-about')

            this.zoneTextAbout.classList.add(this.actions[this.index])

            void this.numberSlide.offsetWidth

            this.numberSlide.style.transform = 'scale(1,1)'

            // Following class
            this.index = (this.index + 1) % this.actions.length
        }, 200)
    }

    setCircle() {
        // Circle Mesh
        const geometry = new THREE.RingGeometry(15, 18.5, 30)
        this.material = new THREE.MeshStandardMaterial({
            color: this.color.one,
            wireframe: true,
            lightMapIntensity: 0
        })
        this.mesh = new THREE.Mesh(geometry, this.material)
        this.mesh.position.set(0, 0, 1)
        this.scene.add(this.mesh)
    }

    onMouseMove(event) {
        this.mouse.x = (event.clientX - this.windowHalf.x)
        this.mouse.y = (event.clientY - this.windowHalf.y)
    }

    update() {
        // If about
        if (this.camera.aboutReady === true) {
            // Rotate circle
            this.mesh.rotation.z -= 0.001

            // If not touch pointer
            if (!window.matchMedia("(pointer: coarse)").matches) {
                // Move circle
                this.target.y = (1 - this.mouse.y) * 0.02

                this.mesh.position.x += 0.02 * (-140 + this.target.y - this.mesh.position.x)
            }
            else {
                // Position circle
                this.mesh.position.set(-150, 0, -1)
            }
        }
    }
}