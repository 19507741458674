import Experience from '../Experience.js'

export default class App {
    constructor() {
        this.experience = new Experience()

        document.addEventListener('DOMContentLoaded', () => {
            this.elements = document.querySelectorAll('[data-i18n]')
            this.toggleLanguageButton = document.getElementById('toggleLanguage')

            this.toggleLanguageButton.addEventListener('click', this.toggleLanguage.bind(this))

            this.translatePage()
        })
    }

    translatePage() {
        this.elements.forEach(element => {
            const translationKey = element.dataset.i18n
            element.innerHTML = this.experience.translation.translations[this.experience.translation.currentLanguage][translationKey]
        })
    }

    toggleLanguage() {
        this.experience.translation.currentLanguage = this.experience.translation.currentLanguage === 'en' ? 'fr' : 'en'
        this.translatePage()
    }
}