import * as THREE from 'three'
import Experience from '../Experience.js'

export default class Background {
    constructor() {
        this.experience = new Experience()
        this.color = this.experience.color
        this.sizes = this.experience.sizes
        this.camera = this.experience.camera
        this.scene = this.experience.scene
        this.resources = this.experience.resources

        // Mouse movement
        this.mouse = this.experience.camera.mouse
        this.target = new THREE.Vector2()
        this.windowHalf = new THREE.Vector2(this.sizes.width / 2, this.sizes.height / 2)

        // Set base circle and second circle
        this.setGeometry()
        this.setMaterial()
        this.setMesh()

        // Set second circle movement
        document.addEventListener('mousemove', this.onMouseMove.bind(this), false)
    }

    setGeometry() {
        // Base circle
        this.geometryBackground = new THREE.CircleGeometry(250, 250)
        // Second circle
        this.geometry = new THREE.CircleGeometry(160, 160)
    }

    setMaterial() {
        // Base circle
        this.materialBackground = new THREE.MeshStandardMaterial({
            color: this.color.two,
            roughness: 1,
            metalness: 0,
            lightMapIntensity: 1
        })

        // Second circle
        this.material = new THREE.MeshStandardMaterial({
            color: this.color.one,
            roughness: 1,
            metalness: 0,
            lightMapIntensity: 1
        })
    }

    setMesh() {
        // Set circle meshes
        this.meshBackground = new THREE.Mesh(this.geometryBackground, this.materialBackground)
        this.meshBackground.position.z = -2
        this.mesh = new THREE.Mesh(this.geometry, this.material)
        this.mesh.receiveShadow = true
        this.scene.add(this.meshBackground, this.mesh)
    }

    onMouseMove(event) {
        this.mouse.x = (event.clientX - this.windowHalf.x)
        this.mouse.y = (event.clientY - this.windowHalf.y)
    }

    update() {
        // If not homeReady
        if (this.camera.aboutReady === true || this.camera.projectReady === true || this.camera.skillsReady === true || this.camera.contactReady === true) {
            // If not touch pointer
            if (!window.matchMedia("(pointer: coarse)").matches) {
                this.target.x = (1 - this.mouse.x) * 0.008
                this.target.y = - (1 - this.mouse.y) * 0.005

                this.mesh.position.x += 0.02 * (this.target.y - this.mesh.position.x)
                this.mesh.position.y += 0.02 * (this.target.x - this.mesh.position.y)
            }
            else {
                this.mesh.position.set(0, 0, 0)
            }
        }
    }
}