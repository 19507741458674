import Experience from '../Experience.js'
import Environment from './Environment.js'
import Background from './Background.js'
import HomeElements from './HomeElements.js'
import Logo from './Logo.js'
import About from './About.js'
import Project from './Project.js'
import Skills from './Skills.js'
import Contact from './Contact.js'

export default class World {
    constructor() {
        this.experience = new Experience()
        this.scene = this.experience.scene
        this.resources = this.experience.resources

        // Wait for resources
        this.resources.on('ready', () => {
            // Setup
            this.background = new Background()
            this.homeElements = new HomeElements()
            this.logo = new Logo()
            this.about = new About()
            this.project = new Project()
            this.skills = new Skills()
            this.contact = new Contact()
            this.environment = new Environment()
        })
    }

    resize() {
        this.homeElements.resize()
        this.skills.resize()
        this.environment.resize()
    }

    update() {
        if (this.background)
            this.background.update()
        if (this.homeElements)
            this.homeElements.update()
        if (this.environment)
            this.environment.update()
        if (this.about)
            this.about.update()
        if (this.project)
            this.project.update()
        if (this.skills)
            this.skills.update()
        if (this.contact)
            this.contact.update()
    }
}