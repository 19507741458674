import Experience from './Experience.js'
import { gsap } from 'gsap'

export default class MouseElement {
    constructor() {
        this.experience = new Experience()
        this.camera = this.experience.camera

        // Setup
        this.animate = null
        this.animateMouse = null

        this.events = false

        // HTML elements
        this.FollowBox = "#body .follow-box"
        this.FollowBoxElt = document.querySelector(this.FollowBox)
        this.PointMouse = "#body .point-mouse"
        this.PointMouseElt = document.querySelector(this.PointMouse)

        this.magnetics = document.querySelectorAll(".magnetic")
        this.magneticsAbout = document.querySelectorAll(".magnetic-about")
        this.magneticsProject = document.querySelectorAll(".magnetic-project")
        this.magneticsMedias = document.querySelectorAll(".magnetic-media")
        this.magneticsHTML = document.querySelectorAll(".magnetic-html")

        // Init targets
        this.initButton()
    }

    initButton() {
        // If not touch pointer
        if (!window.matchMedia("(pointer: coarse)").matches) {
            // Mouse circle
            gsap.set(this.FollowBox, {
                xPercent: -50,
                yPercent: -50,
                scale: 1
            })
            this.handleFollowBox = (e) => {
                if (this.animate === null) {
                    gsap.to(this.FollowBox, {
                        duration: 0.15,
                        delay: 0.2,
                        x: e.clientX,
                        y: e.clientY
                    })
                }
            }

            // Mouse point
            gsap.set(this.PointMouse, {
                xPercent: -50,
                yPercent: -50,
                scale: 1
            })
            this.handlePointMouse = (e) => {
                if (this.animateMouse === null) {
                    gsap.to(this.PointMouse, {
                        duration: 0.15,
                        x: e.clientX,
                        y: e.clientY
                    })
                }
            }

            // Move mouse circle
            window.addEventListener("mousemove", this.handleFollowBox.bind(this))

            // Move mouse point
            window.addEventListener("mousemove", this.handlePointMouse.bind(this))

            // Mouse target for nav and back
            this.magnetics.forEach((magnetic) => {

                this.handleMagneticsMenuEnter = () => {
                    this.animate = magnetic

                    if (this.camera.animGsap === true) {

                        this.FollowBoxElt.classList.add("target")

                        this.setAnimationCircle(magnetic)
                    }

                }
                this.handleMagneticMenuLeave = () => {
                    this.animate = null

                    this.FollowBoxElt.classList.remove("target")
                }
                this.handleMagneticMenuClick = () => {
                    this.FollowBoxElt.classList.remove("target")
                }

                magnetic.addEventListener('mouseenter', this.handleMagneticsMenuEnter.bind(this))
                magnetic.addEventListener('mouseleave', this.handleMagneticMenuLeave.bind(this))
                magnetic.addEventListener('click', this.handleMagneticMenuClick.bind(this))
            })

            // Mouse target for text about
            this.magneticsAbout.forEach((magneticAbout) => {

                this.handleMagneticAboutOver = () => {
                    this.animate = magneticAbout

                    if (this.camera.animGsap === true && this.camera.aboutReady === true) {
                        this.FollowBoxElt.classList.add("target-about")

                        this.setAnimationCircle(magneticAbout)
                    }
                }
                this.handleMagneticAboutLeave = () => {
                    this.animate = null

                    this.FollowBoxElt.classList.remove("target-about")
                }

                magneticAbout.addEventListener('mouseover', this.handleMagneticAboutOver.bind(this))
                magneticAbout.addEventListener('mouseleave', this.handleMagneticAboutLeave.bind(this))
            })

            // Mouse target for project logo
            this.magneticsProject.forEach((magneticProject) => {

                this.handleMagneticProjectEnter = () => {
                    this.animate = magneticProject
                    this.animateMouse = magneticProject

                    if (this.camera.animGsap === true && this.camera.projectReady === true) {
                        this.FollowBoxElt.classList.add("target-project")

                        this.setAnimationCirclePoint(magneticProject)
                    }
                }
                this.handleMagneticProjectLeave = () => {
                    this.animate = null
                    this.animateMouse = null

                    this.FollowBoxElt.classList.remove("target-project")
                }
                this.handleMagneticProjectClick = () => {
                    this.FollowBoxElt.classList.remove("target-project")
                }

                magneticProject.addEventListener('mouseenter', this.handleMagneticProjectEnter.bind(this))
                magneticProject.addEventListener('mouseleave', this.handleMagneticProjectLeave.bind(this))
                magneticProject.addEventListener('click', this.handleMagneticProjectClick.bind(this))
            })

            // Mouse target for media contact
            this.magneticsMedias.forEach((magneticMedia) => {

                this.handleMagneticMediaEnter = () => {
                    this.animate = magneticMedia
                    this.animateMouse = magneticMedia

                    if (this.camera.animGsap === true && this.camera.contactReady === true) {
                        this.FollowBoxElt.classList.add("target-media")

                        this.setAnimationCirclePoint(magneticMedia)
                    }
                }
                this.handleMagneticMediaLeave = () => {
                    this.animate = null
                    this.animateMouse = null

                    this.FollowBoxElt.classList.remove("target-media")
                }

                magneticMedia.addEventListener('mouseenter', this.handleMagneticMediaEnter.bind(this))
                magneticMedia.addEventListener('mouseleave', this.handleMagneticMediaLeave.bind(this))
            })

            // Mouse target for HTML elements
            this.magneticsHTML.forEach((magneticHTML) => {

                this.handleMagneticHTMLEnter = () => {
                    this.animate = magneticHTML

                    if (this.camera.animGsap === true) {
                        this.FollowBoxElt.classList.add("target-html")

                        this.setAnimationCircle(magneticHTML)
                    }
                }
                this.handleMagneticHTMLLeave = () => {
                    this.animate = null

                    this.FollowBoxElt.classList.remove("target-html")
                }
                this.handleMagneticHTMLScroll = () => {
                    this.FollowBoxElt.classList.remove("target-html")
                }

                magneticHTML.addEventListener('mouseenter', this.handleMagneticHTMLEnter.bind(this))
                magneticHTML.addEventListener('mouseleave', this.handleMagneticHTMLLeave.bind(this))
                magneticHTML.addEventListener('scroll', this.handleMagneticHTMLScroll.bind(this))
            })

            // Update events
            this.events = true
        }
    }

    setAnimationCircle(element) {
        // Magnetic mouse circle
        this.animate = element
        const bounds = element.getBoundingClientRect()

        gsap.killTweensOf(this.FollowBox)

        gsap.to(this.FollowBox, {
            x: bounds.x,
            y: bounds.y
        })
    }

    setAnimationCirclePoint(element) {
        //Magnetic mouse point
        this.animate = element
        this.animateMouse = element
        const bounds = element.getBoundingClientRect()

        gsap.killTweensOf(this.FollowBox)
        gsap.killTweensOf(this.PointMouse)

        gsap.to(this.FollowBox, {
            x: bounds.x,
            y: bounds.y
        })
        gsap.to(this.PointMouse, {
            x: bounds.x,
            y: bounds.y
        })
    }

    resizeButton() {
        // If not touch pointer and !events
        if (!window.matchMedia("(pointer: coarse)").matches) {
            if (!this.events)
                this.initButton()
        }
        // Else if events
        else if (this.events) {
            gsap.set(this.FollowBox, {
                xPercent: -50,
                yPercent: -50,
                scale: 1
            })

            gsap.set(this.PointMouse, {
                xPercent: -50,
                yPercent: -50,
                scale: 1
            })

            window.removeEventListener("mousemove", this.handleFollowBox.bind(this))
            window.removeEventListener("mousemove", this.handlePointMouse.bind(this))

            this.magnetics.forEach((magnetic) => {

                magnetic.removeEventListener('mouseenter', this.handleMagneticsMenuEnter.bind(this))
                magnetic.removeEventListener('mouseleave', this.handleMagneticMenuLeave.bind(this))
                magnetic.removeEventListener('click', this.handleMagneticMenuClick.bind(this))
            })

            this.magneticsAbout.forEach((magneticAbout) => {

                magneticAbout.removeEventListener('mouseover', this.handleMagneticAboutOver.bind(this))
                magneticAbout.removeEventListener('mouseleave', this.handleMagneticAboutLeave.bind(this))
            })

            this.magneticsProject.forEach((magneticProject) => {

                magneticProject.removeEventListener('mouseenter', this.handleMagneticProjectEnter.bind(this))
                magneticProject.removeEventListener('mouseleave', this.handleMagneticProjectLeave.bind(this))
                magneticProject.removeEventListener('click', this.handleMagneticProjectClick.bind(this))
            })

            this.magneticsMedias.forEach((magneticMedia) => {

                magneticMedia.removeEventListener('mouseenter', this.handleMagneticMediaEnter.bind(this))
                magneticMedia.removeEventListener('mouseleave', this.handleMagneticMediaLeave.bind(this))
            })

            this.magneticsHTML.forEach((magneticHTML) => {

                magneticHTML.removeEventListener('mouseenter', this.handleMagneticHTMLEnter.bind(this))
                magneticHTML.removeEventListener('mouseleave', this.handleMagneticHTMLLeave.bind(this))
                magneticHTML.removeEventListener('scroll', this.handleMagneticHTMLLeave.bind(this))
                // magneticHTML.removeEventListener('click', this.handleMagneticHTMLClick.bind(this))
            })

            // Update events
            this.events = false
        }
    }
}